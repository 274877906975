import { render, staticRenderFns } from "./channel_fee_list.vue?vue&type=template&id=6b7fd7e4&scoped=true"
import script from "./channel_fee_list.vue?vue&type=script&lang=js"
export * from "./channel_fee_list.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6b7fd7e4",
  null
  
)

export default component.exports